.container {
    background-color: #D6F1F2;
    border-radius: 10px;
    display: flex;
    gap: 40px;
    align-items: flex-start;
    padding: 30px;
    height: 400px;
    width: 100%;
}

.flexEnd {
    direction: rtl;
}

.flexEnd > * {
    direction: ltr;
}

@media (width < 900px) {
    .container {
        flex-direction: column;
        height: auto;
    }
}