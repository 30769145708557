.step-description-container {
  width: 350px;
  opacity: 0;
  animation: fadeinout 1.2s forwards;
}

.step-description-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 32px;
  letter-spacing: 0.1px;
}

.step-description-text {
  font-size: 18px;
  color: var(--text-black);
  margin-bottom: 30px;
  line-height: 28px;
}

.step-description-link-container {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.step-description-link {
  color: #424b5a;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 2px solid #424b5a;
}

.step-description-link-icon path {
  fill: #424b5a;
}

@keyframes fadeinout {
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 786px) {
    .step-description-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .step-description-title, .step-description-text  {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .step-description-text {
        margin-bottom: 60px;
    }
}
