.containerRoot {
    --background-color: #fff;
}

.containerRootBlue {
    --background-color: #D6F1F2;
}

.containerRootTrans {
    --background-color: transparent;
}

.containerWrap {
    background-color: #fff;
}

.container {
    margin: 0 auto;
    position: relative;
    background-position: right top, left bottom;
    background-repeat: no-repeat, no-repeat;
    max-width: 1800px;    
}

.content {
    padding: 40px;
    padding-bottom: 80px;

    max-width: 1440px;
    margin: 0 auto;
    position: relative;
}

.contentBorder {
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    position: absolute;
    z-index: 1;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: var(--background-color);
}


.containerRoot {
}



.containerRootBlue .container {
    background-color: #D6F1F2;
    max-width: none;  
}

.containerRootTrans .content,
.containerRootBlue .content {
    padding-top: 80px;
}


@media (width < 900px) {
    .content {
        padding: 20px;
    }
}

@media (width > 1200px) {
    .contentBorder {
        opacity: 0;
    }
}
