.tos-bg {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tos-content-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.tos-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.item-title {
  font-weight: 700;
}

.tos-title {
  text-align: start;
  font-family: Space Grotesk !important;
  font-size: 40px;
  margin-bottom: 0;
}
