.label {
  color: var(--neutral-neutral-0, #0a0a0a);
  font-family: Space Grotesk !important;
  font-size: 14px;
  font-weight: 600;
}

.label-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label-Left {
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.label-Down {
  flex-direction: column-reverse;
  align-items: center;
}

.label-Right {
  flex-direction: row-reverse;
  gap: 20px;
}

