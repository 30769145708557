.breaker-horizontal {
    height: 2px;
    opacity: .6;
    width: 100%;
    background: linear-gradient(to right, #01A5AF, #54C0E2, #FEC1A7);
}

.breaker-vertical {
    width: 2px;
    opacity: .6;
    height: 100%;
    background: linear-gradient(to top, #01A5AF, #54C0E2, #FEC1A7);
}

.breaker-disable_gradient {
    background: none;
}