.container {
    background-color: #d6f1f23b;
    border: 1px solid #01A5AF;
    width: 300px;
    aspect-ratio: 1 / 0.7;
    border-radius: 8px;
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
}