.MarkdownShow {
    
}

.MarkdownShow ol {
    list-style: decimal;
    margin-left: 1rem;
}

.MarkdownShow span {
    font-size: inherit !important;
    color: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    display: inline;
}

.MarkdownShow h1 {
    font-size: 1.5rem;
    color: var(--primary);
    font-weight: 700;
    line-height: 3.5rem !important
}

.MarkdownShow h2 {
    color: var(--primary);
    @apply text-3xl font-semibold
}

.MarkdownShow h3 {
    font-size: 1rem;
    font-weight: 700;
}

.MarkdownShow h4 {
    @apply font-bold text-zinc-600 text-justify text-lg
}

.MarkdownShow h5 {
    @apply text-lg font-bold
}

.MarkdownShow p {
    @apply text-zinc-600 text-justify text-lg
}

.MarkdownShow div {
    @apply px-0
}

.MarkdownShow ul {
    @apply pl-1.5 list-disc ml-2
}

.MarkdownShow table {
    border-right: 1px solid #e1e1e1;
    border-collapse: collapse;
}

.MarkdownShow thead th {
    background-color: #e1e1e1;
    padding: var(--gap);
}

.MarkdownShow td {
    border-bottom: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    vertical-align: baseline;
    padding: var(--gap);
}

.MarkdownShow hr {
    height: 3px;
    opacity: .6;
    width: 100%;
    background: linear-gradient(to right, #01A5AF, #54C0E2, #FEC1A7);
}

.MarkdownShow.tos h1 {
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--black);
    font-family: Space Grotesk !important;
    line-height: 3.5rem;
}

.MarkdownShow.tos h3 {
    font-size: 1.25rem;
    font-weight: 600;
}

.MarkdownShow.privacy_policy h1 {
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--black);
    line-height: 3.5rem;
}

.MarkdownShow.privacy_policy h3 {
    font-size: 1.25rem;
    font-weight: 600;
}

.MarkdownShow.privacy_policy th {
    background-color: #e1e1e1;
    padding: var(--gap);
}

.MarkdownShow a {
    font-weight: normal;
}