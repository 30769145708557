.container {
    background-color: #01a5af24;
    color: #01A5AF;
    padding: 5px 15px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.container:hover {
    cursor: pointer;
    opacity: 0.8;
}

.container:active {
    opacity: 0.9;
}

.selected {
    background-color: #01a5af69;
    color: #035d63;
}