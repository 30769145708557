.container {
    margin: 0 auto;
}

.thin {
    max-width: 970px;
}

.marginTitle {
    margin-top: 40px;
    margin-bottom: 50px;
}

.marginHeader {
    margin-top: 40px;
    margin-bottom: 10px;
    width: -webkit-fill-available;
}

.center {
    margin: 0 auto;
    text-align: center;
}

.alignCenter {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
}