.DropMenu {
    position: relative;
    display: flex;
    align-items: center;
}

.DropMenu .header:hover {
    cursor: pointer;
    opacity: 0.8;
}

.DropMenu .header:active {
    opacity: 0.9;
}

.DropMenu .menu {
    position: absolute;
    background-color: var(--white);
    right: 0;
    width: 250px;
    z-index: 60;
    box-shadow: 0px 5px 8px #333;
    padding: var(--gap-m);
    top: 100%;
}