.popIframe {
    position: fixed;
    background-color: #44444494;
    inset: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popIframe > .container {
    width: 80vw;
    height: 80vh;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 7px 8px 15px #333;
}


.popIframe > .container > .top {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: flex-end;
}

.popIframe > .container > .top > svg {
    cursor: pointer;
}

.popIframe > .container > .content {
    padding: 20px;
    overflow: auto;
    height: calc(100% - 4rem);
}