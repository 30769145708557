.step-image-container {
  display: flex;
  width: 350px;
  height: 300px;
  align-items: self-start;
  justify-content: flex-end;
  opacity: 0;
  animation: fadeinout 1.2s forwards;
}

.step-image-1 {
  height: 170px;
  width: 210px;
}

.step-image-2 {
  height: 180px;
  width: 180px;
}

.step-image-3 {
  height: 150px;
  width: 180px;
}

.step-image-4 {
  height: 180px;
  width: 170px;
}

@keyframes fadeinout {
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
    .step-image-container {
        justify-content: center;
        height: 200px;
    }
}
