.container {
    background-color: #D7F1F2;
    border: 1px solid #7AD1D5;
    color: #6a8081;
    padding: 8px 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.selected {
    background-color: #C5E5E8;
}

.thin {
    padding: 4px 12px;
    font-size: 12px;
    color: #000;
    border: none;
}