.container {
    display: flex;
    width: 100%;
}

.container > * {
    flex: 1;
}

.container > :nth-child(1) {
    background-position: 305% -10%;
    background-size: 92%;
}

.container > :nth-child(2) {
    margin-top: 10%;
    background-position: 3% 18%;
}

.container > :nth-child(3) {
    margin-top: 20%;
    background-position: 112% 10%;
}


@media (width < 900px) {
    .container {
        flex-direction: column;
        margin-bottom: 30px;
    }
}