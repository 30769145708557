.picWrap {
    background-color: #e1e1e1;
    width: 100%;
    aspect-ratio: 1 / 0.5;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}

.picWrap > img {
    background-size: contain;
}