:root {
  --primary: #01a5af;
  --horizontal-padding: 16px;
  --vertical-padding: 10px;
  --text-black: #5C5C5C;
  --primary-text-0: #182223;
  --background: #f1f7fa;
  --white: #f1f7fa;

  --gap: 10px;
  --gap-m: 16px;
  --gap-l: 22px;
  --gap-s: 8px;
}
