.container {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 40px;
}

.box {
    border: 1px solid #01A5AF;
    padding: 24px;
    border-radius: 20px;
    width: 100%;
}

@media (width < 900px) {
    .box > * {
        flex-direction: column;
    }
}