.swiggle {
    background-repeat: no-repeat;
    background-position: -20% 100%;
}

.icon {
    background-color: #fff;
    height: 150px;
    width: 210px;
    background-size: auto 60%;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #01A5AF;
    border-radius: 13px;
}

.text {
    padding-right: 20%;
}

@media (width < 900px) {
    .icon {
        margin: 0 auto;
    }
    .text {
        padding-right: 0;
        text-align: center;
    }
    body .swiggle .rows {
        align-items: center;
    }
}