.blogs-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.blogs-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1110px;
  margin-top: 90px;
  margin-bottom: 40px;
}

.blogs-title {
  margin: 0 !important;
}

.blogs-see-all {
  color: var(--primary);
  font-weight: 600;
  cursor: pointer;
}

.blogs-container {
  width: 1110px;
  height: 610px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.blog {
  border-radius: 14px;
  display: flex;
  align-items: flex-end;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: white;
  letter-spacing: 0.8px;
  cursor: pointer;
}

.blog-content {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  padding: 20px;
  border-radius: 14px;
}

.blog-1 .blog-content {
  padding: 60px;
}

.blog-1 {
  grid-area: 1 / 1 / 3 / 4;
  font-size: 38px;
  letter-spacing: 2px;
  line-height: 50px;
}

.blog-2 {
  grid-area: 1 / 4 / 2 / 5;
}

.blog-3 {
  grid-area: 2 / 4 / 3 / 5;
}

.blogs-see-all-mobile {
    display: none;
    color: var(--primary);
    font-weight: 600;
    font-size: 20px;
    padding-top: 20px;
}

@media screen and (max-width: 1024px) {
  .blogs-title-container {
    width: 900px;
  }
  
  .blogs-container {
    width: 900px;
    height: 610px;
  }
}

@media screen and (max-width: 900px) {
    .blogs-section {
        padding-bottom: 50px;
    }

    .blogs-title-container {
        justify-content: center;
        width: 90%;
        margin-top: 50px;
    }
    
    .blogs-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;
      width: 100%;
    }

    .blogs-see-all {
        display: none;
    }

    .blog-content-text {
        font-size: 31px;
        line-height: 42px;
    }

    .blog {
        width: 90% !important;
        height: 400px;
    }

    .blog-1 .blog-content {
        padding: 30px;
    }
    
    .blog-1 {
        height: 600px;
    }

    .blogs-see-all-mobile {
        display: flex;
    }
  }

  .blog-link {
    color: #fff;
    text-decoration: none;
  }
  
  .blog-link:hover {
    color: #ddd;
  }
  
